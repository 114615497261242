const ProjectItemsArray = [
  {
    id: 1,
    name: "iNotebook",
    ImageLink: "https://i.postimg.cc/CMZcBdF0/image-1.png",
    description:
      "Cloud Notepad is a web-based note-taking application that allows users to create, edit, and access their notes from anywhere in the world. The application provides a secure and convenient platform for users to organize their thoughts, ideas, and information seamlessly.",
    techstack: ["Node", "React", "Express", "MongoDB", "Bootstrap"],
    projectLink: "https://github.com/adityapatel21/iNotebook/tree/master",
    githubLink: "https://github.com/adityapatel21/iNotebook/tree/master",
  },
  {
    id: 2,
    name: "NewsFusionWave",
    ImageLink: "https://i.postimg.cc/43FjT5sd/image-2.png",
    description:
      "News-fetching powerhouse driven by the dynamic capabilities of React, and turbocharged by the ever-flowing News API, to deliver a seamless and immersive news experience like never before.",
    techstack: ["React", "JavaScript", "News API", "CSS", "Bootstrap"],
    githubLink: "https://github.com/adityapatel21/NewsFusionWave",
  },
  {
    id: 3,
    name: "WhatsAppBot",
    ImageLink: "https://i.postimg.cc/L4jQyRYh/image-4.png",
    description:
      "While not everyone may opt for WhatsApp Business, the demand for efficient bulk dynamic message sending has never been greater. Harnessing the power of Python and Selenium, we're automating your browser to revolutionize bulk dynamic message delivery via WhatsApp Web.",
    techstack: ["Selenium", "Python"],
    githubLink: "https://github.com/adityapatel21/whatsapp_bulk_message",
  },
  {
    id: 4,
    name: "Sales Anaylysis",
    ImageLink: "https://i.postimg.cc/y6J3BYXY/image-5.png",
    description:
      "Unlocking the potential within sales data through the art of Python and the precision of Pandas. We're not just analyzing, we're optimizing. Discovering the perfect moments for advertisement to propel sales to new heights.",
    techstack: ["Pandas", "Matplotlib", "NumPy", "Python"],
    githubLink:
      "https://github.com/adityapatel21/SalesDataAnalysis/tree/master",
  },
  {
    id: 5,
    name: "Face Recognition",
    ImageLink: "https://i.postimg.cc/TYd8gh7T/image-3.png",
    description:
      "Elevating the attendance tracking process with the magic of OpenCV and Python. It's not just marking attendance; it's reshaping the way we record and manage attendance seamlessly.",
    techstack: ["OpenCV", "NumPY", "Python"],
    githubLink: "https://github.com/adityapatel21/face_recogntion/tree/master",
  },
];

export default ProjectItemsArray;
