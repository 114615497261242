import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div id="loader">
      <div className="loadingio-spinner-eclipse-4bp3mo2d5ft">
        <div className="ldio-5hog4dtga4y">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
